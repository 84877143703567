/* You can add global styles to this file, and also import other style files */
@import './assets/sass/style.scss';
// Replace above style with this css file to enable RTL styles
// @import "./assets/css/style.rtl";
@import './assets/sass/plugins.scss';
// @import "./assets/css/style.rtl.css";
@import './assets/sass/style.angular.scss';

@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

.dashboard-new .popover {
  --bs-popover-max-width: 380px !important;
}

html,
html .mat-primary {
  --mat-select-trigger-text-font: 'GT Eesti Pro Display', Helvetica, sans-serif;
  --mat-select-trigger-text-line-height: 1.25;
  --mat-select-trigger-text-size: 1rem;
  --mat-select-trigger-text-tracking: 0;
  --mat-select-trigger-text-weight: 400;

  --mat-option-selected-state-label-text-color: var(--bs-gray-800);
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: var(--bs-gray-300);
  --mat-option-selected-state-layer-color: rgb(239, 243, 246);

  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: var(--bs-gray-700);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);

  --mdc-filled-text-field-caret-color: #009ef7;
  --mdc-filled-text-field-focus-active-indicator-color: #009ef7;
  --mdc-filled-text-field-focus-label-text-color: var(--bs-gray-700);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #f44336;
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #009ef7;
  --mdc-outlined-text-field-focus-outline-color: #009ef7;
  --mdc-outlined-text-field-focus-label-text-color: var(--bs-gray-800);
  --mdc-outlined-text-field-label-text-color: var(--bs-gray-800);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-error-hover-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: var(--bs-gray-600);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-focus-select-arrow-color: #009ef7;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #f44336;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;

  --mat-full-pseudo-checkbox-selected-icon-color: #009ef7;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: var(--bs-gray-500);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #009ef7;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;

  --mat-select-container-elevation-shadow: 1px 1px 0px 0px var(--bs-gray-300),
    -1px -1px 0 0 var(--bs-gray-300);

  --mdc-outlined-text-field-label-text-size: 1rem;
  --mdc-outlined-text-field-label-text-weight: 600;
  --mdc-outlined-text-field-label-text-tracking: -0.5px;

  --mat-stepper-container-text-font: 'GT Eesti Pro Display', Helvetica, sans-serif;
  --mat-stepper-header-label-text-font: 'GT Eesti Pro Display', Helvetica, sans-serif;
  --mat-stepper-header-label-text-size: 1rem;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-weight: 400;

  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 1px;
  --mdc-outlined-text-field-container-shape: 16px;

  --mat-option-label-text-font: 'GT Eesti Pro Display', Helvetica, sans-serif;
  --mat-option-label-text-line-height: 1.25;
  --mat-option-label-text-size: 1rem;
  --mat-option-label-text-tracking: 0;
  --mat-option-label-text-weight: 400;

  --mat-form-field-container-height: 36px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 0;
  --mat-form-field-filled-with-label-container-padding-top: 0px;
  --mat-form-field-filled-with-label-container-padding-bottom: 0px;
  --mat-mdc-form-field-label-transform: 1rem;

  --mat-form-field-outlined-label-text-populated-size: 14px;
}

.cdk-overlay-container {
  z-index: 1060;
}

.modal-header {
  justify-content: space-between !important;
}
